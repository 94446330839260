<script>
export default {
  name: 'ThuxtableChecksFieldsMixin',
  data () {
    return {
      localSelectedList: [],
      fieldName: 'id'
    }
  },
  created () {
    this.setSelectedList([])
    this.setSelectAll(false)
  },
  computed: {
    getLocalSelectedAll () {
      let selectAll = true
      if (!this.selectAll && this.list) {
        this.list.forEach((instance) => {
          if (!this.localSelectedList.some((item) => item[this.fieldName] === instance[this.fieldName])) {
            selectAll = false
          }
        })
      }
      return selectAll
    }
  },
  methods: {
    setSelectedRow (value, fieldName = 'id') {
      if (this.selectAll) {
        this.setSelectAll(false)
        this.setSelectedList([])
      }
      const selectedList = this.selectedList.filter(function (instance) {
        return instance[fieldName] !== value[fieldName]
      })
      if (selectedList.length === this.selectedList.length) {
        selectedList.push(value)
      }
      this.setSelectedList(selectedList)
    },
    checkAll (fieldName = 'id') {
      this.list.forEach((instance) => {
        if (
          !this.localSelectedList.some(
            (item) => item[fieldName] === instance[fieldName]
          )
        ) {
          this.localSelectedList.push(instance)
        }
      })
      this.setSelectedList(this.localSelectedList)
    },
    checkNone (fieldName = 'id') {
      this.setSelectAll(false)
      this.list.forEach((instance) => {
        this.localSelectedList = this.localSelectedList.filter(
          (item) => item[fieldName] !== instance[fieldName]
        )
      })
      this.setSelectedList(this.localSelectedList)
    }
  }
}
</script>
