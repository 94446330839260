<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import { TYPES as OWNER_ORG_TYPES } from './store'
import { ROUTES as OWNER_ORG_ROUTES } from './router'

import ThuxListMixin from '../../../../../components/thux-list/ThuxListMixin'

import OwnerOrganizationTable from './OwnerOrganizationTable'

export default {
  name: 'OwnerOrganizationList',
  mixins: [ThuxListMixin],
  components: {
    'component-table': OwnerOrganizationTable
  },
  data () {
    return {
      OWNER_ORG_ROUTES,
      title: this.$t('Owner organizations'),
      rolePerm: ['organization_ownerorganization_list'],
      actionEnablePermission: ['organization_ownerorganization_enable'],
      actionDisablePermission: ['organization_ownerorganization_disable'],
      showCommonFilters: false
    }
  },
  computed: {
    ...mapGetters({
      ...OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.GETTERS
    }),
    showList () {
      return this.$route.name === this.OWNER_ORG_ROUTES.OWNER_ORGANIZATION_LIST
    },
    canSeeActionSelect () {
      return true
    },
    canAddInstance () {
      return false
    }
  },
  methods: {
    ...mapMutations({
      setSelectedList: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.MUTATIONS.setSelectedList,
      setSelectAll: OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.MUTATIONS.setSelectAll
    }),
    ...mapActions({
      ...OWNER_ORG_TYPES.GENERIC.organization.ownerorganization.LIST.ACTIONS
    }),
    openDetailForm (id) {
      this.$router.push({ name: this.OWNER_ORG_ROUTES.OWNER_ORGANIZATION_DETAIL, params: { id: id } })
    }
  }
}
</script>
